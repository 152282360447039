<template>
  <div class="userrolesmanagmentTable">
    <DataTable
      :value="items"
      removableSort
      :paginator="true"
      dataKey="id"
      :rows="10"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      :sortOrder="-1">
      <template #header>
        <div class="table-header">
          <h4>App Owner Management</h4>
          <small>You Mustbe SuperAdmin To Add The App Owner</small>
          <div class="p-col-1-cust">
            <Button
             v-if="isUserSuperAdmin"
              label="Add App Owner"
              class="p-button-secondary iconmargin"
              @click="handleaddAdminUser()"
              icon="fas fa-plus"
            />
          </div>
        </div>
      </template>
      <template #empty> No users found. </template>
      <div class="tablecols">
        <Column
          field="displayName"
          header="Name"
          headerClass="title"
          bodyClass="title"
          sortable>
        </Column>
        <Column
          field="email"
          header="Email Id"
          headerClass="title"
          bodyClass="title"
          sortable>
        </Column>
        <Column
          v-if="isUserSuperAdmin"
          field="actions"
          header="Actions"
          headerClass="title"
          bodyClass="title">
           <template #body="slotProps">
                 <Button label="Disable Super Admin" 
                 icon="pi pi-times"  
                 v-if="slotProps.data.isSuperAdmin" 
                @click="handleDisableSuperAdmin(slotProps.data)"
                 class="p-button-warning" />

                 <Button label="Enable Super Admin" 
                 icon="pi pi-check"  v-else 
                  @click="handleEnableSuperAdmin(slotProps.data)"
                 class="p-button-secondary"/>
                 
                 <Button label="Delete App Owner" icon="pi pi-trash" 
                  @click="handleRemoveSuperAdmin(slotProps.data)"
                 class="p-ml-2 p-button-secondary"/>
           </template>
        </Column>
      </div>
    </DataTable>
    <Dialog
      :visible.sync="newAdminDialog"
      header="Add New Admin"
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
      :style="{ width: '70vw' }"
    >
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-10 p-m-5">
          <span class="p-float-label">
            <AutoComplete
              v-model="userDetails"
              :suggestions="filteredUsers"
              @complete="searchOwnerWithName($event)"
              :multiple="true"
              field="mail"
            >
              <template #item="slotProps">
                <div>
                  <div>
                    <span><i class="fas fa-user"></i></span>
                    {{ slotProps.item.displayName }}
                  </div>
                  <div>
                    <span><i class="fas fa-envelope-open-text"></i></span>
                    {{ slotProps.item.mail }}
                  </div>
                </div>
              </template>
            </AutoComplete>
            <label for="inputtext" class="floatlabel">Application Admins</label>
          </span>
          <label class="error-message"> {{ validation.firstError("userDetails") }}</label>
          <label class="error-message" v-if="invalidUserNaem">Invalid User name</label>
          <small id="username1-help">search with user name</small>
        </div>
      </div>
      <template #footer>
        <Button
          label="Save Admin"
          icon="pi pi-check"
          class="p-button-secondary"
          @click="handlesaveAppAdmins()"
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          @click="handlecloseDialog()"
        />
      </template>
    </Dialog>
     <ConfirmDialog></ConfirmDialog>
  </div>
</template>
<script>
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  name: "usermanagmentDataTable",
  created() {},
  computed:{
     isUserAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserAdmin || false;
    },
    isUserSuperAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserSuperAdmin || false;
    }   
  },
  components: {},
  methods: {
    handleaddAdminUser() {
      this.userDetails = null;
      this.newAdminDialog = true;
      this.userDetails="";
       this.validation.reset();
    },
    handlecloseDialog() {
      this.userDetails = null;
      this.newAdminDialog = false;
      this.validation.reset();
    },
    async handlesaveAppAdmins() {
      this.submitted = true;
      await this.$validate().then((success) => {
      if (success) {
      let appAdmins = [];
      let users = this.userDetails;
        users.forEach((user) => {
          let addAdmin = {
            id: user.id,
            displayName: user.displayName,
            email: user.mail,
          };
          appAdmins.push(addAdmin);
        });
      this.$parent.saveAppAdmins(appAdmins);
      this.handlecloseDialog();
      }
      });
    },
    handleDisableSuperAdmin(user){
      this.$confirm.require({
        message: 'Do you want to disable super admin?',
        header: 'Disable Confirmation',
        acceptClass: 'p-button-danger',
        accept: () => {
          let _user = {...user};
          _user.isSuperAdmin=false;
          this.$parent.disableSuperAdmin(_user);
          this.$toast.add({severity:'success', summary: 'Success Message', detail:'disabled super admin', life: 2000});
        },
        reject: () => {}
      })
    },
    handleEnableSuperAdmin(user){
      this.$confirm.require({
        message: 'Do you want to enable super admin?',
        header: 'Enable Confirmation',
        acceptClass: 'p-button-danger',
        accept: () => {
        let _user = {...user};
        _user.isSuperAdmin=true;
        this.$parent.enableSuperAdmin(_user);
        this.$toast.add({severity:'success', summary: 'Success Message', detail:'enabled super admin', life: 2000});
        },
        reject: () => {}
      })
    },
    handleRemoveSuperAdmin(user){
      this.$confirm.require({
      message: 'Do you want to delete this app owner?',
      header: 'Delete Confirmation',
      acceptClass: 'p-button-danger',
      accept: () => {
        let _user = {...user};
        this.$parent.removeSuperAdmin(_user);
        this.$toast.add({severity:'success', summary: 'Success Message', detail:'App owner is deleted', life: 2000});
      },
      reject: () => {}
    });
    },
    async searchOwnerWithName(event) {
      let filterquery = event.query;
      await this.getUsersWithname(filterquery);
    },
    async getUsersWithname(filterquery) {
      try {
        let result = await this.$store.dispatch(
          "user/getUsersWithname",
          filterquery
        );
        this.filteredUsers = result.data;
        this.invalidUserNaem= result.data.length < 1? true:false;
        setTimeout(() => {
          this.invalidUserNaem=false;
        }, 2000);
      } catch (error) {
        console.warn(error);
      }
    },
  },
  validators: {
    "userDetails": function (value) {
        return Validator.value(value).required().custom(() =>{
          if (!Validator.isEmpty(value)) {
          let user = value;
          console.log(user);
          }
      });
    }
  },
  props: {
    items: { type: Array },
  },
  data() {
    return {
      filteredUsers: [],
      userDetails: "",
      newAdminDialog: false,
      checked: true,
      invalidUserNaem:false
    };
  },
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
  top:-1rem !important;
}
</style>
