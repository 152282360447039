<template>
  <DataTable :value="dummyData()">
    <template #header v-if="items.isRequiredHeader">
      <div class="table-header">
        <div>
          <Skeleton width="27rem" height="3rem"></Skeleton>
        </div>
        <div class="p-mr-2">
          <Skeleton width="17rem" height="3rem"></Skeleton>
        </div>
      </div>
    </template>
      <Column v-for="col of items.columns" :field="col.field" :header="col.header" :key="col.field">
        <template #body>
         <Skeleton></Skeleton>
      </template>
      </Column>
  </DataTable>
</template>
<script>
export default {
  name: "SkeletonDataTable",
  created() {},
  components: {},
  methods: {},
  props: {
     items: { type: Object },   
  },
  watch: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>