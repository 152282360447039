<template>
<div class="userManagmentView">
   <div v-if="hasSuperAdminRights">
      <div class="card">
        <SkeletonTable ref="skeletonTable" v-if="isloading" :items="skeletonTable" />
        <UserManagmentDataTable ref="usermanagmenttable"  :items="adminUsers" v-else />
      </div>
   </div>
  <div v-else>
    <ForbiddenView/>
  </div>
</div>
</template>
<script>
import SkeletonTable from "@/components/Shared/SkeletonDataTable.vue";
import UserManagmentDataTable from "@/components/Settings/AdminUserDataTable.vue";
import ForbiddenView from "../Errors/403.vue"
export default {
  name: "userManagmentView",
  created() {
  this.getAppAdminUsers();
  this.hasAdminRights=localStorage.getItem("isUserAdmin")=='true'?true:false;
  this.hasSuperAdminRights=localStorage.getItem("isUserSuperAdmin")=='true'?true:false
  },
  components:{SkeletonTable,UserManagmentDataTable,ForbiddenView},
  methods:{
  async saveAppAdmins(admins){
      try {
        await this.$store.dispatch("user/saveappAdmins",admins);
        this.getAppAdminUsers();
      } catch (error) {
        console.warn(error);
      }
   },
   async getAppAdminUsers(){
      try {
        this.isloading=true; 
        let result = await this.$store.dispatch("user/getAppAdminUsers");
        this.adminUsers = result.data;
        this.isloading=false;
      } catch (error) {
        console.warn(error);
      }
   },
   async disableSuperAdmin(user){
      try {
        await this.$store.dispatch("user/updateAdminUser",user);
        this.getAppAdminUsers();
      } catch (error) {
        console.warn(error);
      }
   },
   async enableSuperAdmin(user){
      try {
        await this.$store.dispatch("user/updateAdminUser",user);
        this.getAppAdminUsers();
      } catch (error) {
        console.warn(error);
      }
   },
   async removeSuperAdmin(user){
      try {
        await this.$store.dispatch("user/removeAdminUser",user);
        this.getAppAdminUsers();
      } catch (error) {
        console.warn(error);
      }
   }  
  },
  props: {},
  watch: {},
  data(){
    return{
    isloading:true,
    hasAdminRights:false,
    hasSuperAdminRights:false,
    adminUsers:null,
    skeletonTable:{
      columns:[
        {field: 'name', header: 'Name'},
        {field: 'email', header: 'Email Id'},
        {field: 'actions', header: 'Actions'},
        ],
      isRequiredHeader:true,
    },     
    }
  }
}
</script>
<style scoped>
</style>
